import React from 'react'
import './Loader.css'
import loader from '../Asset/dambaruu.png'
const Loader = () => {
  return (
    <>
      <img className='loader' src={loader} alt='' />
    </>
  )
}

export default Loader
