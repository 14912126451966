import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import "./MissingNumbers.css";
import backIcon from "../../Asset/backbtn.png";
import backgroundVideo from '../../Asset/images/Train_1.gif';
import backgroundImg from '../../Asset/images/miss_num_bg1.png';
import Switch from "react-switch";
import congratulation from "../../Asset/cards/numbergame/correct-awesome.png"
import lose from '../../Asset/cards/numbergame/You-lose.png'
import { IoSettingsOutline, IoVolumeHigh, IoHome,IoPlay  } from "react-icons/io5";
import { FaPause } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function MissingNumbers() {
  const numSets = 10;
  const completeSeries = Array.from({ length: 100 }, (_, i) => i + 1);
  const [incompleteSeries, setIncompleteSeries] = useState(
    generateIncompleteSeries(completeSeries, numSets, 1)
  );

  const [currentSet, setCurrentSet] = useState(1);
  const [playGIF, setPlayGIF] = useState(true);
  const [gifPlaybackCompleted, setGIFPlaybackCompleted] = useState(false);
  const incompleteSeriesRef = useRef(incompleteSeries);
  const [volume, setVolume] = useState(0.5);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const correctAudioRef = useRef(new Audio("/Congrats.wav"));
  const incorrectAudioRef = useRef(new Audio("/Sorry.Wrong.wav"));
  const audioRef = useRef(new Audio("/village-15345.mp3"));
  const history = useHistory();
  const [count, setCount] = useState(0); 
  console.log(count)
  const [showPauseBox, setShowPauseBox] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [pausedSetIndex, setPausedSetIndex] = useState(null);
     // setting modal states
  const [showSettings, setShowSettings] = useState(false);
  const handleCloseSettings = () => setShowSettings(false);
  const handleShowSettings = () => setShowSettings(true);
    // CongratulationModal modal states
  const [showCongratulationModal, setShowCongratulationModal] = useState(false);
  const handleCloseCongratulationModal = () => setShowCongratulationModal(false);
  const handleShowCongratulationModal = () => setShowCongratulationModal(true);
  // WrongModal modal states
  const [showWrongModal, setShowWrongModal] = useState(false);
  const handleCloseWrongModal = () => setShowWrongModal(false);
  const handleShowWrongModal = () => setShowWrongModal(true);

  const currentSeriesStart = currentSet;
  const currentSeriesEnd = currentSet + numSets - 1;

  useEffect(() => {
    const gifTimeoutId = setTimeout(() => {
      setPlayGIF(false);
      setGIFPlaybackCompleted(true);
    }, 2500);
    const audio = new Audio('/village-15345.mp3');
    audio.loop = true;
    audio.volume = isMuted ? 0 : volume;
    audioRef.current = audio; // Set the audio element reference
    const handleCanPlayThrough = () => {
      setIsAudioLoaded(true);
      audio.play();
    };
    audio.addEventListener('canplaythrough', handleCanPlayThrough);
    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener('canplaythrough', handleCanPlayThrough);
      clearTimeout(gifTimeoutId);
    };
  }, [isMuted,volume]);

  const resetSeries = () => {
    const newCurrentSet = currentSet + numSets;

    if (newCurrentSet <= completeSeries.length) {
      const newIncompleteSeries = generateIncompleteSeries(completeSeries, numSets, newCurrentSet);
      setIncompleteSeries(newIncompleteSeries);
      incompleteSeriesRef.current = newIncompleteSeries;
      setCurrentSet(newCurrentSet);
    } else {
      setCurrentSet(1);
      const newIncompleteSeries = generateIncompleteSeries(completeSeries, numSets, 1);
      setIncompleteSeries(newIncompleteSeries);
      incompleteSeriesRef.current = newIncompleteSeries;
    }
  };

  useEffect(() => {
    if (!incompleteSeries.includes(null)) {
      const isComplete = incompleteSeries.every(
        (value, index) => value === completeSeries[index + currentSet - 1]
      );
      if (isComplete) {
        setTimeout(() => {
          resetSeries();
        }, 2000);
        setShowCongratulationModal(true);
        correctAudioRef.current.play();
      } else {
        setShowWrongModal(true);
        incorrectAudioRef.current.play();
        setIncompleteSeries(incompleteSeriesRef.current);
        setTimeout(() => {
          setShowWrongModal(false);
        }, 2000);
      }
    }
  }, [incompleteSeries, completeSeries, currentSet, numSets]);

  useEffect(() => {
    if (showCongratulationModal) {
      setTimeout(() => {
        // Check if all sets are completed, then reset the count to 0
        if (currentSet + numSets > completeSeries.length) {
          setCount(0);
        } else {
          // Increment count by 100 for each set completed
          setCount((prevCount) => prevCount + 100);
        }
        setShowCongratulationModal(false);
      }, 2000);
    }
  }, [showCongratulationModal]);

  const handleDragStart = (e, value) => {
    e.dataTransfer.setData("text/plain", value.toString());
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const value = parseInt(e.dataTransfer.getData("text/plain"));
    const newSeries = [...incompleteSeries];
    newSeries[index] = value;
    setIncompleteSeries(newSeries);
  };

  useEffect(() => {
    // Event handler for beforeunload
    const handleBeforeUnload = () => {
      // Pause and reset audio elements
      correctAudioRef.current.pause();
      correctAudioRef.current.currentTime = 0;
      incorrectAudioRef.current.pause();
      incorrectAudioRef.current.currentTime = 0;
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

  return () => {
      // Cleanup event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);
      // Cleanup audio elements
      correctAudioRef.current.pause();
      correctAudioRef.current.currentTime = 0;
      incorrectAudioRef.current.pause();
      incorrectAudioRef.current.currentTime = 0;
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, []);

    // Toggle the mute state
    const handleToggleChange1 = () => {
      setIsMuted(!isMuted);
      };

    const home1 = () => {
     history.push("/dashboard");
      };
      
    const handlePauseClick = () => {
    // Pause the game and remember the current set index
    setIsPaused(true);
    setPausedSetIndex(currentSet);
    setShowPauseBox(true);
    audioRef.current.pause(); // Pause the background music
    };
        
    const handlePauseModalClose = () => {
    //close the modal and restart the game 
    setShowPauseBox(false);
    setIsPaused(false);
    setCurrentSet(pausedSetIndex);
    audioRef.current.play(); // Play the background music
    };

  //  BackButton click sound
  let backbutton = new Audio( "/Button Click 2.wav" );
  const back = () => {
    backbutton.play();
  };

  return (
    <div className="main-container">
      <span className="arrowback" onClick={back}>
          <img
            src={backIcon}
            onClick={() => history.goBack()}
            className="miss-backbutton"
            alt=""
          />
     </span>
         
     <div className="num-con">
      {isAudioLoaded && (
        <>
      <div>
          <Button className="miss-pause-btn" onClick={handlePauseClick}><FaPause  size="1.6rem" /></Button>
                {/* modal for pause button  */}
            <Modal show={showPauseBox} className="miss-pause-modal" centered>
              <Modal.Header >
                <Modal.Title className="miss-pause-title">Game Paused</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="miss-pause-para">This game is paused now.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button className="miss-play-btn" variant="primary" onClick={handlePauseModalClose}>
                <IoPlay  size="2.5rem" />
                </Button>
              </Modal.Footer>
            </Modal>
        <Button className="heading">
          <p className="head-para">Complete The Missing Number</p>
        </Button>
    <div className="miss-num-count"> 
     <p className='miss-count-value'>{count}
     <img src="./images/coin.png" alt="Coin" className="miss-coin-image" /></p>
    </div>
    </div>
      <div className={`gif-container ${playGIF ? 'play' : ''}`}
        style={{
          backgroundImage: `url(${playGIF ? backgroundVideo : backgroundImg})`,
        }} />
        {gifPlaybackCompleted && !playGIF && (
          <div>
            <div className="series-container">
              {/* Render the incomplete series with drop zones */}
              {incompleteSeries.map((value, index) => (
                <div
                  key={index}
                  className="drop-zone"
                  onDrop={(e) => handleDrop(e, index)}
                  onDragOver={(e) => allowDrop(e)}
                >
                 {value !== null ? value : <span className="blank"></span>}
                </div>
                ))}
            </div>
  <footer className="miss-num-footer">
  <p className="miss-current-set">{currentSeriesStart} - {currentSeriesEnd}</p>
      <div className="complete-series">
        {/* Render the complete series with draggable numbers */}
        {completeSeries.slice(currentSet - 1, currentSet + numSets - 1).map((value) => (
        <div
          key={value}
          className="draggable-number"
          draggable
          onDragStart={(e) => handleDragStart(e, value)}
        >
          {value} 
        </div>
         ))}
      </div>
    <Button className="miss-set-btn" variant="primary" onClick={handleShowSettings}><IoSettingsOutline size="1.6rem" />
     </Button>
    <Button className="miss-home-btn" variant="primary" onClick={home1}><IoHome size="1.5rem" /></Button>
  </footer>
  </div>    
    )}
  </>
  )}
 </div>
 {/* modal for setting button */}
 <Modal show={showSettings} className="miss-setting-modal">
    <Modal.Header>
     <Modal.Title className="miss-set-title">SETTING</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="miss-volume-icon"><IoVolumeHigh size="2rem" />
        <Switch className="miss-switch"
         onChange={handleToggleChange1}
         checked={!isMuted}
         onColor="#86d3ff"
         offColor="#aaa"
         checkedIcon={<div style={{ display: "flex", justifyContent: "center", 
          alignItems: "center", height: "100%" }}>ON</div>}
         uncheckedIcon={<div style={{ display: "flex", justifyContent: "center",
          alignItems: "center", height: "100%" }}>OFF</div>}
        />
      </div>
    </Modal.Body>
      <Modal.Footer>
        <Button className="miss-back-btn" onClick={handleCloseSettings}>
          BACK
        </Button>
      </Modal.Footer>
  </Modal>
  {/* modal for congratulation  */}
  <Modal show={showCongratulationModal} className="miss-congrats-modal" onHide={handleCloseCongratulationModal} centered>
    <Modal.Body>
    <img src={congratulation} className="miss-msg-complete"/>
    </Modal.Body>
  </Modal>
  {/* modal for wrong message  */}
  <Modal show={showWrongModal} className="miss-lose-modal" onHide={handleCloseWrongModal} centered>
    <Modal.Body>
      <img src={lose} className="miss-msg-lose"/>
    </Modal.Body>
  </Modal>
</div>
  );
}

function generateIncompleteSeries(completeSeries, numSets, startSet) {
  const pattern = [];
  for (let i = startSet - 1; i < startSet - 1 + numSets; i++) {
    if (Math.random() < 0.5) {
      pattern.push(null); // Blank space
    } else {
      pattern.push(completeSeries[i]);
    }
  }
  return pattern;
}

export default MissingNumbers;

