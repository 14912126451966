import React, { useState, useEffect } from 'react';
import './Alphabet.css';
import Header from '../Header/Header';
import Loader from '../Loader';
import axios from '../../utils/api';
import { apiEndPoint } from '../../utils/constants';

const Alphabet = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flippedCard, setFlippedCard] = useState(null);
  const [currentSound, setCurrentSound] = useState(null);

  const handleClick = async (id, soundUrl) => {
    if (flippedCard !== id) {
      // If the clicked card is the front image
      if (currentSound) {
        currentSound.pause(); // Pause the currently playing sound
        setCurrentSound(null);
      }
      const audio = new Audio(soundUrl);
      await audio.play();
      setCurrentSound(audio);
    }
    // Toggle the flipped card ID
    setFlippedCard(flippedCard === id ? null : id);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(apiEndPoint.alphabet);
        if (res.data && res.data.data) {
          setData(res.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      if (currentSound) {
        currentSound.pause();
        setCurrentSound(null);
      }
    };
  }, [currentSound]);

  return (
    <div className="tracing-container">
      <Header />
      <div>
        <h1>Let's Play With Alphabets</h1>
      </div>
      <div className="tracing-block">
        {loading ? (
          <div className="loading">
            <Loader />
          </div>
        ) : (
          <div className="All-tracing-block-card">
            <div className="tracing-block-card">
              {data.map((alphabet) => (
                <div className="tracing-card-front" key={alphabet.id}>
                  <img
                    src={
                      flippedCard === alphabet.id ? alphabet.image_back : alphabet.image_front
                    }
                    alt="Alphabet"
                    onClick={() => {
                      handleClick(alphabet.id, alphabet.sound);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Alphabet;