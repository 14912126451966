import React, { useState, useEffect,useRef } from "react";
import Header from "../Header/Header";
import { useHistory } from "react-router";
import Switch from "react-switch";
import { IoSettingsOutline, IoVolumeHigh, IoHome,IoPlay  } from "react-icons/io5";
import { FaPause } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import congratulation from '../../Asset/cards/numbergame/correct-awesome.png'
import lose from '../../Asset/cards/numbergame/You-lose.png'
import "./NumberGame.css";

const NumberGame = () => {
  const generateQuestions = () => {
    const numberOfQuestions = 10;
    const minNumber = 1;
    const maxNumber = 20;
    const questions = [];

    // Array of background images for different sets
    const backgroundImages = [
      "./images/F_1.png",
      "./images/E_1.png",
      "./images/D_1.png",
      "./images/C_1.png",
      "./images/A_1.png",
      "./images/B_1.png",
      "./images/G_1.png",
      "./images/H_1.png",
      "./images/J_1.png",
      "./images/I_1.png",
    ];

    for (let i = 0; i < numberOfQuestions; i++) {
      const num1 =
        Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
      const correctNumber = num1;
      const question = `Identify the Number ${num1}`;

      // Use a different background image for each set
      const backgroundImage = backgroundImages[i];

      const numbers = [
        { value: correctNumber, backgroundImage },
        { value: correctNumber + 2, backgroundImage },
        { value: correctNumber + 1, backgroundImage },
        { value: correctNumber + 3, backgroundImage },
        { value: correctNumber + 4, backgroundImage },
        { value: correctNumber - 1, backgroundImage },
      ].sort(() => Math.random() - 0.5);

      questions.push({
        question,
        numbers,
        correctNumber,
        backgroundImage,
      });
    }

    return questions;
  };

  const [questions, setQuestions] = useState(generateQuestions());
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedNumberIndex, setSelectedNumberIndex] = useState(null);
  const [showGoodMessage, setShowGoodMessage] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [correctAnswerCount, setCorrectAnswerCount] = useState(0);
  const [volume, setVolume] = useState(0.1);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [pausedSetIndex, setPausedSetIndex] = useState(null);
  const [showPauseBox, setShowPauseBox] = useState(false);
  const audioRef = useRef(null); 
  const history = useHistory();
    // setting modal states
  const [showSettings, setShowSettings] = useState(false);
  const handleCloseSettings = () => setShowSettings(false);
  const handleShowSettings = () => setShowSettings(true);
    // WrongModal modal states
  const [showWrongModal, setShowWrongModal] = useState(false);
  const handleCloseWrongModal = () => setShowWrongModal(false);
  const handleShowWrongModal = () => setShowWrongModal(true);
    // CongratulationModal modal states
  const [showCongratulationModal, setShowCongratulationModal] = useState(false);
  const handleCloseCongratulationModal = () => setShowCongratulationModal(false);
  const handleShowCongratulationModal = () => setShowCongratulationModal(true);

  const handleNumberClick = (numberIndex) => {
    const currentQuestion = questions[currentQuestionIndex];
    const clickedNumber = currentQuestion.numbers[numberIndex].value;
    const sorryAudio = new Audio("/Sorry.wrong.wav");
    if (clickedNumber === currentQuestion.correctNumber) {
      setShowGoodMessage(true);
      setSelectedNumberIndex(numberIndex);
      setIsCorrectAnswer(true);
      setCorrectAnswerCount(correctAnswerCount + 1);
    } else {
      // Show the wrong message modal
      setShowWrongModal(true);
      sorryAudio.play();
       // Close the wrong modal after 2 seconds
       setTimeout(() => {
        setShowWrongModal(false);
        sorryAudio.pause(); // Pause the sorry audio
        sorryAudio.currentTime = 0;
      }, 2000);
    }
  };

      // Toggle the mute state
  const handleToggleChange = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    // Check if the game is paused before executing the game logic
    if (!isPaused && showGoodMessage) {
      const congAudio = new Audio("/Congrats.wav");
    
      setTimeout(() => {
        setShowGoodMessage(false);
        setSelectedNumberIndex(null);

        if (isCorrectAnswer) {
          const nextQuestionIndex = (currentQuestionIndex + 1) % questions.length;

          // Check if all sets are completed
          if (nextQuestionIndex === 0) {
            setCorrectAnswerCount(0); // Reset the correct answer count for the new set
            setShowCongratulationModal(true);
            congAudio.play();
            // Close the congratulation modal after 2 seconds
            setTimeout(() => {
              setShowCongratulationModal(false);
              congAudio.pause(); // Pause the congratulation audio
              congAudio.currentTime = 0;
              setCurrentQuestionIndex(0); // Reset to the first set
            }, 2000);
          } else {
            setCurrentQuestionIndex(nextQuestionIndex);
          }
        } else {
          setShowWrongModal(true);
        }

        setIsCorrectAnswer(false);
      }, 1500);

      return () => {
        congAudio.pause();
        congAudio.currentTime = 0;
      };
    }
  }, [isPaused, showGoodMessage, currentQuestionIndex, isCorrectAnswer, questions]);

  useEffect(() => {
    const audio = new Audio("/FastFeelBananaPeel-320bit.mp3");

    audio.loop = true;
    audio.volume = isMuted ? 0 : volume;

    audioRef.current = audio; // Set the audio element reference

    const handleCanPlayThrough = () => {
      setIsAudioLoaded(true);
      audio.play();
    };

    audio.addEventListener("canplaythrough", handleCanPlayThrough);

    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener("canplaythrough", handleCanPlayThrough);
    };
  }, [isMuted, volume]);

  const currentQuestion = questions[currentQuestionIndex];
  const numbers = currentQuestion.numbers;

  const Home = () => {
    history.push( "/dashboard" );
  };

  const handlePauseClick = () => {
    // Pause the game and remember the current set index
    setIsPaused(true);
    setPausedSetIndex(currentQuestionIndex);
    setShowPauseBox(true);
    audioRef.current.pause(); // Pause the background music
};

 const handlePauseModalClose = () => {
  //close the modal and restart the game 
    setShowPauseBox(false);
    setIsPaused(false);
    setCurrentQuestionIndex(pausedSetIndex);
    audioRef.current.play(); // Play the background music
  };

  return (
  <div className={`background-image ${isPaused ? 'blur' : ''}`}>
    <Header />
      <div className="number-game">
        {isAudioLoaded && (
        <>
          <div className="correct-answer-count">
              {/* <p className="count-text">Correct Answers</p> */}
              <p className="count-value">{correctAnswerCount} /{questions.length} 
              <img src="./images/coin.png" alt="Coin" className="coin-image" />
              </p>
              <Button className="set-btn" variant="primary" onClick={handleShowSettings}><IoSettingsOutline size="1.6rem" /></Button>
              <Button className="home-btn" variant="primary" onClick={Home}><IoHome size="1.5rem" /></Button>
          </div>
            <Button className="pause-btn" onClick={handlePauseClick}><FaPause  size="1.6rem" /></Button>
                {/* modal for pause button  */}
            <Modal show={showPauseBox} className="identify-pause-modal" centered>
              <Modal.Header >
                <Modal.Title className="pause-title">Game Paused</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="pause-para">This game is paused now.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button className="play-btn" variant="primary" onClick={handlePauseModalClose}>
                <IoPlay  size="2.5rem" />
                </Button>
              </Modal.Footer>
            </Modal>
          <div className="game-content">
          <div className="question">
            <p className="question-text">{currentQuestion.question}</p>
          </div>
          <div className="num-container">
            {numbers.map((number, index) => (
              <div
                key={index}
                className={`number ${
                  selectedNumberIndex === index
                  ? isCorrectAnswer
                      ? "correct"
                      : "incorrect"
                  : ""
                }`}
               style={{
                backgroundImage: `url(${number.backgroundImage})`,
                     }}
                onClick={() =>
                  isCorrectAnswer ? null : handleNumberClick(index)
                    }
              >
                {number.value}
              </div>
                ))}
          </div>
                  {/* modal for setting button */}
          <Modal show={showSettings} className="identify-setting-modal">
              <Modal.Header>
                <Modal.Title className="ide-set-title">SETTING</Modal.Title>
              </Modal.Header>
            <Modal.Body>
              <div className="volume-icon"><IoVolumeHigh size="2rem" />
                <Switch className="switch"
                  onChange={handleToggleChange}
                  checked={!isMuted}
                  onColor="#86d3ff"
                  offColor="#aaa"
                  checkedIcon={<div style={{ display: "flex", justifyContent: "center", 
                   alignItems: "center", height: "100%" }}>ON</div>}
                  uncheckedIcon={<div style={{ display: "flex", justifyContent: "center",
                   alignItems: "center", height: "100%" }}>OFF</div>}
                 />
              </div>
            </Modal.Body>
              <Modal.Footer>
                <Button className="back-btn" onClick={handleCloseSettings}>
                  BACK
                </Button>
              </Modal.Footer>
          </Modal>
            </div>
              {/* modal for wrong message  */}
          <Modal show={showWrongModal} className="lose-modal" onHide={handleCloseWrongModal} centered>
              <Modal.Body>
                <img src={lose} className="msg-lose"/>
              </Modal.Body>
          </Modal>
              {/* modal for congratulation  */}
          <Modal show={showCongratulationModal} className="correct-modal" onHide={handleCloseCongratulationModal} centered>
              <Modal.Body>
                <img src={congratulation} className="complete-set"/>
              </Modal.Body>
          </Modal>
        </>
        )}
      </div>
    </div>
  );
};

export default NumberGame;