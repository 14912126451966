import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Content,
  Dashboard,
  Login,
  SubjectList,
  Video,
  Activities,
  PrivateRoute,
} from "./Components";
import Alphabet from "./Components/Alphabet/Alphabet";
import MissingNumbers from "./Components/MissingNumbers/MissingNumbers";
import MatchingLetters from "./Components/MatchingLetters/MatchingLetters";
import Number from "./Components/Number/Number";
import NumberGame from "./Components/NumberGame/NumberGame";
import Thumbnails from "./Components/Thumbnails/Thumbnails";
import Tracingalphabet from "./Components/Tracingalphabet/Tracingalphabet";
import NumAdditions from "./Components/NumAdditions/Addition";
import NumSubtractions from "./Components/NumSubtractions/Subtraction";
import Getbundle from "./Components/Getbundle/Getbundle";
import Loginpage2 from "./Components/Loginpage2/Loginpage2";
import Update from "./Components/Update/Update";
import { appRoute } from "./utils/constants";
import "bootstrap/dist/css/bootstrap.min.css";
process.env.TESSDATA_PREFIX = process.env.REACT_APP_TESSDATA_PREFIX;

const { login, dashboard, subject, content, video, activity, addition, alphabet, thumbnails, 
  tracingalphabet, number, numbergame, missingnumbers, matchingletters, subtraction,getbundle,loginpage2,update} = appRoute;


const App = () => {

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path={login} component={Login} />
          <PrivateRoute exact path={dashboard} component={Dashboard} />
          <Route path= {activity} component={Activities}/>
          <Route path={alphabet} component={Alphabet} />
          <Route path={missingnumbers} component={MissingNumbers} />
          <Route path={matchingletters} component={MatchingLetters} />
          <Route path={number} component={Number} />
          <Route path={numbergame} component={NumberGame} />
          <Route path={thumbnails} component={Thumbnails} />     
          <Route path={tracingalphabet} component={Tracingalphabet} />
          <Route path={subject} component={SubjectList} />
          <PrivateRoute path={content} component={Content} />
          <PrivateRoute path={video} component={Video} />
          <Route path= {addition} component={NumAdditions}/>
          <Route path= {subtraction} component={NumSubtractions}/> 
          <Route path={update} component={Update} />    
          <Route path={loginpage2} component={Loginpage2} /> 
          <Route path={getbundle} component={Getbundle} /> 
          
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
