import React, { useState, useEffect } from "react";
import "./Getbundle.css";
import Header from "../Header/Header";
import { useHistory } from "react-router-dom";

const Getbundle = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const history = useHistory();
  useEffect(() => {
    // Add event listeners to check internet status
    window.addEventListener('online', checkInternetStatus);
    window.addEventListener('offline', checkInternetStatus);
    return () => {
      window.removeEventListener('online', checkInternetStatus);
      window.removeEventListener('offline', checkInternetStatus);
    };
  }, []);

 // check internet connection 
  const checkInternetStatus = () => {
    setIsOnline(navigator.onLine);
  };
  const update= () => {
    history.push("/loginpage2");
  };
  
  return (
    <div className="right-side-part1">
      <div className="nav-part">
        <Header />
      </div>
         {/* update button */}
              {isOnline && (
              <div>
             <button className="content-update-button" onClick={update}>
            update
          </button> 
          </div>
          )}
    </div>
  );
};

export default Getbundle;

