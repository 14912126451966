import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Update.css";
import { config } from "../../utils/api";
import Header from "../Header/Header";
import Loader from "../Loader";
import { toast } from "react-toastify";

const Update = () => {
  const [data, setData] = useState([]);
  const [selectedBundleData, setSelectedBundleData] = useState([0]);
  const [age, setAge] = useState([]);
  const [sub, setSub] = useState([]);
  const [topic, setTopic] = useState([]);
  const [thumb, setThumb] = useState([]);
  const [video, setVideo] = useState([]);
  const [currentBundleIndex, setCurrentBundleIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedSchoolName, setSelectedSchoolName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [progress, setProgress] = useState(0);
  const [displayedProgress, setDisplayedProgress] = useState(0);

  const handleSchoolNameClick = (schoolName) => {
    const selectedSchool = data.find((item) => item.school_name === schoolName);
    if (selectedSchool) {
      setSelectedBundleData(selectedSchool.bundles);
      if (selectedSchool.bundles.length > 0) {
        const firstBundle = selectedSchool.bundles[0];
        setAge([firstBundle.age]);
        setSub([firstBundle.sub]);
        setTopic([firstBundle.top]);
        setThumb([firstBundle.thumbnail_image_path]);
        setVideo([firstBundle.content_file_path]);
      }
      setSelectedSchoolName(selectedSchool); // Updated this line
    }
  };

  const sendSelectedData = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < selectedBundleData.length; i++) {
        const currentBundle = selectedBundleData[i];
        const formData = new FormData();
        formData.append("standard_name", currentBundle.age);
        formData.append("subject_name", currentBundle.sub);
        formData.append("topic_name", currentBundle.top);
        formData.append("video", currentBundle.content_file_path);
        formData.append("thumbnail", currentBundle.thumbnail_image_path);
        await axios.post(
          `http://localhost:8000/courses/update_content/`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const progressPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(progressPercentage);
              setDisplayedProgress(progressPercentage);
            },
          }
        );
      }
      setCurrentBundleIndex(0);
    } catch (error) {
      console.error("Error:", error);
      toast.success("please wait...Try again");
    } finally {
      setLoading(false);
      toast.success("Successfully content updated");
    }
  };
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `https://www.staging4321.dambaru.org:8000/courses/schoolname/`, config()
        )
        .then((res) => {
          setData(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, []);

  const handelChange = (event) => {
    const selectedValue = event.target.value;
    const selectedSchoolObject = data.find(
      (option) => option.school_name === selectedValue
    );
    setSelectedSchoolName(selectedSchoolObject);
    handleSchoolNameClick(selectedValue);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredSchools = data.filter((item) =>
    item.school_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="right-side-part2">
    <div className="nav-part">
      <Header />
    </div>
    <input type="text" 
     class="form-control" 
     placeholder="Search Bundle Name"
     value={searchTerm}
     onChange={handleSearchChange} />
    <div>
      <select class="form-select"
       value={selectedSchoolName.school_name} onChange={handelChange}>
        <option value="">Select a Bundle</option>
        {filteredSchools.map((item) => (
          <option key={item.id} value={item.school_name}>
            {item.school_name}
          </option>
        ))}
      </select>
    </div>
    <div>
      <button
        className="submitt-button"
        disabled={!selectedSchoolName.school_name}
        onClick={sendSelectedData}
      >
        {loading ? <Loader /> : "Update"}
      </button>
    </div>
  </div>
  );
};

export default Update;