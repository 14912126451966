import React, { useState ,useRef} from 'react';
import './ColorPicker.css';

const ColorPicker = ({ onSelectColor, onPencilWidthChange }) => {
  const [selectedColor, setSelectedColor] = useState('');
  const [pencilWidth, setPencilWidth] = useState(5);

  const colorToPencilImage = {
    '#f00': process.env.PUBLIC_URL + '/image/Red.png',
    '#57df3c': process.env.PUBLIC_URL + '/image/Green.png',
    '#55bbda': process.env.PUBLIC_URL + '/image/Blue1.png',
    '#9212db': process.env.PUBLIC_URL + '/image/Purple.png',
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
    onSelectColor(color);

    // Add selected class to the clicked pencil color
    const pencilColors = document.querySelectorAll('.pencil-color');
      pencilColors.forEach((pencil) => {
      pencil.classList.remove('selected');
    });

    const selectedPencil = document.querySelector(`.pencil-color[data-color="${color}"]`);
    if (selectedPencil) {
      selectedPencil.classList.add('selected');
    }
  };

  const handlePencilMouseLeave = (color) => {
    const selectedPencil = document.querySelector(`.pencil-color[data-color="${color}"]`);
    if (selectedPencil) {
      selectedPencil.classList.remove('selected');
    }
  };
  const handlePencilWidthChange = (event) => {
    const width = parseInt(event.target.value, 10);
    const roundedWidth = Math.round(width / 5) * 5; // Round to the nearest multiple of 5
    const newWidth = roundedWidth <= 100 ? roundedWidth : 100; // Ensure the width doesn't exceed 100%
    onPencilWidthChange(newWidth);
    setPencilWidth(newWidth);
  };
  
  return (
    <>
      <div className="color-picker-container">
        <div className='pencil-colors'>
          {Object.keys(colorToPencilImage).map((color) => (
            <>
            <div
              key={color}
              className={`pencil-color ${selectedColor === color ? 'selected' : ''}`}
              style={{
                backgroundImage: `url(${colorToPencilImage[color]})`,
                height: '15rem',
                width: '5rem',
                transition: 'transform 0.2s ease-in-out',
                transform: selectedColor === color ? 'scale(1.3)' : 'scale(1)',
              }}
              onClick={() => handleColorClick(color)}
              onMouseLeave={() => handlePencilMouseLeave(color)}
              data-color={color}
            >
            </div>
           </>
          ))}
        </div>
        <div className="pencil-width-indicator">
          {pencilWidth}px  Thickness
        </div>
        <input
          className='input-part'
          type="range"
          min="10"
          max="100"
          value={pencilWidth}
          onChange={handlePencilWidthChange}
        />
      </div>
    </>
  );
};
export default ColorPicker;
