import React, { useState, useEffect, useRef } from "react";
import Header from '../Header/Header';
import axios, { config } from "../../utils/api";
import { useHistory } from "react-router";
import Switch from "react-switch";
import { IoSettingsOutline, IoVolumeHigh, IoHome,IoPlay  } from "react-icons/io5";
import { FaPause } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import congratulation from "../../Asset/cards/numbergame/correct-awesome.png"
import "./Addition.css";
import { apiEndPoint } from "../../utils/constants";
import Loader from "../Loader";

function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

function Addition() {
  const [sets, setSets] = useState([]);
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");
  const [loading, setLoading] = useState(false);
  const congratsAudio = new Audio("/Congrats.wav");
  // const incorrectAudio = new Audio("/Sorry.Wrong.wav");
  const [isMuted, setIsMuted] = useState(false);
  const [count, setCount] = useState(0); 
  const [volume, setVolume] = useState(0.1);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showPauseBox, setShowPauseBox] = useState(false);
  const audioRef = useRef(null); 
  const history = useHistory();
    // setting modal states
  const [showSettings, setShowSettings] = useState(false);
  const handleCloseSettings = () => setShowSettings(false);
  const handleShowSettings = () => setShowSettings(true);
   // CongratulationModal modal states
   const [showCongratulationModal, setShowCongratulationModal] = useState(false);
   const handleCloseCongratulationModal = () => setShowCongratulationModal(false);
   const handleShowCongratulationModal = () => setShowCongratulationModal(true);

   const [allImages, setAllImages] = useState([]);
   
   const fetchImages = async () => {
    try {
      const response = await axios.get(apiEndPoint.addition, config());
      const images = response.data.data.map(item => item.image);
      setAllImages(images); // Save all images in state
      return images;
    } catch (error) {
      console.error("Error fetching images:", error.response || error);
      throw new Error("Failed to fetch images");
    }
  };

  useEffect(() => {
    const audio = new Audio('/FastFeelBananaPeel-320bit.mp3');
    audio.loop = true;
    audio.volume = isMuted ? 0 : volume;
    audioRef.current = audio; // Set the audio element reference
    const handleCanPlayThrough = () => {
      setIsAudioLoaded(true);
      audio.play();
    };
    audio.addEventListener('canplaythrough', handleCanPlayThrough);
    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener('canplaythrough', handleCanPlayThrough);
    };
  }, [isMuted,volume]);

  const generateAdditionSets = async () => {
    setLoading(true);
    const newSets = [];
  
    // Shuffle the order of images once and use the shuffled array sequentially
    const shuffledImages = shuffleArray(allImages);
  
    for (let i = 0; i < 3; i++) {
      let num1, num2;
      let correctAnswer;
  
      // Ensure unique correct answers for each set
      do {
        num1 = Math.floor(Math.random() * 9) + 1;
        num2 = Math.floor(Math.random() * 9) + 1;
        correctAnswer = num1 + num2;
      } while (newSets.some((set) => set.correctAnswer === correctAnswer));
  
      try {
        const selectedImage = shuffledImages[i % shuffledImages.length];
  
        const imageSetNum1 = Array.from({ length: num1 }, () => selectedImage);
        const imageSetNum2 = Array.from({ length: num2 }, () => selectedImage);
  
        const correctOptionImages = imageSetNum1.concat(imageSetNum2);
        const correctOption = {
          value: correctAnswer,
          images: correctOptionImages,
        };
  
        const allOptions = shuffleArray([correctOption]);
  
        newSets.push({
          num1,
          num2,
          correctAnswer,
          options: allOptions,
          userAnswer: null,
        });
      } catch (error) {
        console.error("Error generating addition set:", error);
      }
    }
  
    setSets(newSets);
    setLoading(false);
  };

  useEffect(() => {
    fetchImages(); // Fetch images only once when the component mounts
  }, []);

  useEffect(() => {
    generateAdditionSets();
  }, [currentSetIndex, allImages]);

  const handleSetCompletion = () => {
    setMessage("");
    if (currentSetIndex < 10) {
      // Show correct modal here
      setShowCongratulationModal(true);
      congratsAudio.play();
      // After a delay, move to the next set
      setTimeout(() => {
        setShowCongratulationModal(false);
        setCurrentSetIndex(currentSetIndex + 3); // Move to the next set of 3
        generateAdditionSets(); // Regenerate sets after completing a set
      }, 2000); // Adjust the delay as needed
    } else {
      setCurrentSetIndex(0);
      setCount(0);
      setSets(sets.map(set => ({ ...set, userAnswer: null })));
      generateAdditionSets(); // Regenerate sets after completing all sets
    }
  };

  const handleDrop = (event, setIndex, optionIndex) => {
    event.preventDefault();
    const droppedAnswer = event.dataTransfer.getData("text/plain");
    const updatedSets = [...sets];
    const currentSet = updatedSets[setIndex];

    if (parseInt(droppedAnswer) === currentSet.correctAnswer) {
      currentSet.userAnswer = parseInt(droppedAnswer);
      setMessage("Correct!");
      setMessageClass("correct-message");
      setCount((prevCount) => prevCount + 10);
      setTimeout(() => {
        setMessage("");
        const allCompleted = updatedSets.every(set => set.userAnswer !== null);
        if (allCompleted) {
          handleSetCompletion();
        }
      }, 2000);
    } else {
      // Display the incorrect answer temporarily
      currentSet.userAnswer = parseInt(droppedAnswer);
      setMessageClass("wrong-message");
      setTimeout(() => {
        currentSet.userAnswer = null;
        setMessageClass("");
      }, 2000);
    }
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    generateAdditionSets();
  }, [currentSetIndex]);

  // Toggle the mute state
  const handleToggleChange2 = () => {
    setIsMuted(!isMuted);
  };

  const Home = () => {
    history.push( "/dashboard" );
  };

  const handlePauseClick = () => {
    // Pause the game and remember the current set index
    setIsPaused(true);
    setShowPauseBox(true);
    audioRef.current.pause(); // Pause the background music
};

 const handlePauseModalClose = () => {
  //close the modal and restart the game 
    setShowPauseBox(false);
    setIsPaused(false);
    audioRef.current.play(); // Play the background music
  };


  return (
    <div className="addition-main-container">
      <Header />
      <p className="add-para">One digit addition</p>
      <div className="add-ans-count">
              <p className="add-count-value">
              {count}
              <img src="./images/coin.png" alt="Coin" className="add-coin-image" />
              </p>
              <Button className="add-set-btn" variant="primary" onClick={handleShowSettings}><IoSettingsOutline size="1.6rem" /></Button>
                    {/* modal for setting button */}
          <Modal show={showSettings} className="add-setting-modal">
              <Modal.Header>
                <Modal.Title className="add-set-title">SETTING</Modal.Title>
              </Modal.Header>
            <Modal.Body>
              <div className="add-volume-icon"><IoVolumeHigh size="2rem" />
                <Switch className="add-switch"
                  onChange={handleToggleChange2}
                  checked={!isMuted}
                  onColor="#86d3ff"
                  offColor="#aaa"
                  checkedIcon={<div style={{ display: "flex", justifyContent: "center", 
                   alignItems: "center", height: "100%" }}>ON</div>}
                  uncheckedIcon={<div style={{ display: "flex", justifyContent: "center",
                   alignItems: "center", height: "100%" }}>OFF</div>}
                 />
              </div>
            </Modal.Body>
              <Modal.Footer>
                <Button className="add-back-btn" onClick={handleCloseSettings}>
                  BACK
                </Button>
              </Modal.Footer>
          </Modal>
              <Button className="add-home-btn" variant="primary" onClick={Home}><IoHome size="1.5rem" /></Button>
          </div>
          <Button className="add-pause-btn" onClick={handlePauseClick}><FaPause  size="1.6rem" /></Button>
                {/* modal for pause button  */}
            <Modal show={showPauseBox} className="add-pause-modal" centered>
              <Modal.Header >
                <Modal.Title className="add-pause-title">Game Paused</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="add-pause-para">This game is paused now.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button className="add-play-btn" variant="primary" onClick={handlePauseModalClose}>
                <IoPlay  size="2.5rem" />
                </Button>
              </Modal.Footer>
            </Modal>
      {loading ? ( 
        <Loader />
      ) : (
      <div className="add-set">
        {sets.map((currentSet, setIndex) => (
          <React.Fragment key={setIndex}>
            <div
              className="add-equation"
              onDrop={(e) => handleDrop(e, setIndex, currentSetIndex)}
              onDragOver={(e) => allowDrop(e)}
            >
              <div className="add-equation-box">
                <div className="add-equation-num1">
                  {currentSet.num1 > 0 &&
                    currentSet.options[0].images.slice(0, currentSet.num1).map((image, imageIndex) => (
                      <img className="add-equation-image" key={imageIndex} src={image} alt={`Image ${imageIndex}`} />
                    ))}
                </div>
               <span style={{color:'white'}}>+</span> 
                <div className="add-equation-num2">
                  {currentSet.num2 > 0 &&
                    currentSet.options[0].images.slice(currentSet.num1, currentSet.num1 + currentSet.num2).map((image, imageIndex) => (
                      <img className="add-equation-image" key={imageIndex} src={image} alt={`Image ${imageIndex}`} />
                    ))}
                </div>
                <span style={{color:'white'}}>=</span>
                {currentSet.userAnswer !== null ? (
                    <div className="add-answer">
              <span className={`add-answer-number ${currentSet.userAnswer === currentSet.correctAnswer ? 'correct-message' : 'wrong-message'}`}>
                  {currentSet.userAnswer}
              </span>
              </div>
                ) : (
                  <span className="add-equation-placeholder"> </span>
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
  <div className="add-options-row">
    {sets.map((currentSet, setIndex) => (
      <React.Fragment key={setIndex}>
        {currentSet.options.map((option, optionIndex) => (
          <div
            key={optionIndex}
            draggable="true"
            onDragStart={(e) => e.dataTransfer.setData("text/plain", option.value)}
          >
            <div className="add-option-number">
              {option.value}
            </div>
          </div>
        ))}
      </React.Fragment>
    ))}
  </div>
    {/* modal for congratulation  */}
  <Modal show={showCongratulationModal} className="add-congrats-modal1" onHide={handleCloseCongratulationModal} centered>
   <Modal.Body>
    <img src={congratulation} className="add-complete-set1"/>
    </Modal.Body>
  </Modal>
  </div>
      )}
    </div>
  );
}
export default Addition;