import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ReactPlayer from "react-player";
import video from "../../Asset/landing-video.mp4";
import "./Login.css";
import { Form, Formik } from "formik";
import axios from "../../utils/api";
import { toast } from "react-toastify";
import contactSchema from "../../utils/validations";
import { useDispatch } from "react-redux";
import { login } from "../../utils/reducer";
import { apiEndPoint } from "../../utils/constants";
import logo from "../../Asset/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Modal } from "react-bootstrap";
import expired_soon from "../../Asset/images/expired_soon_card.png"
import token_expired from "../../Asset/images/subscription_end.png"
import { RxCrossCircled } from "react-icons/rx";

const year = new Date();
const fullYear = year.getFullYear();
const Login = () => {
  // added Login Click Sound
  let audio = new Audio("/Button Click 2.wav");
  const start = () => {
    audio.play();
  };
  
  // subscription ended modal
  const [showModal, setShowModal] = useState(false); // State variable for modal
  const [errorMessage, setErrorMessage] = useState(" ");
  // console.log(errorMessage)
  const [errorMessage1, setErrorMessage1] = useState(" ");
  // console.log(errorMessage1)

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [userName, setUserName] = useState(null);
  // console.log(userName)
  const [showExpiredSoonModal, setShowExpiredSoonModal] = useState(false);
  // console.log(showExpiredSoonModal)
  const handleCloseExpiredSoonModal = () => setShowExpiredSoonModal(false);
  const handleShowExpiredSoonModal = () => {
    setShowExpiredSoonModal(true);
  };

  const [renewalDate, setRenewalDate] = useState(null);
  // console.log(renewalDate)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

 // calculate renewale date logic
 useEffect(() => {
  const calculateRenewalDate = () => {
    if (errorMessage1 !== null) {
      const remainingDaysAsNumber = parseInt(errorMessage1, 10);

      if (!isNaN(remainingDaysAsNumber)) {
        const currentDate = new Date();
        const renewalDate = new Date(currentDate);
        renewalDate.setDate(renewalDate.getDate() + remainingDaysAsNumber);
        // Format the renewal date to DD/MM/YYYY
        const formattedRenewalDate =
          renewalDate.getDate() +
          '/' +
          (renewalDate.getMonth() + 1) + // Adding 1 because getMonth() returns zero-based months
          '/' +
          renewalDate.getFullYear();

        setRenewalDate(formattedRenewalDate);
      } else {
        console.error('Invalid remaining days:', errorMessage1);
      }
    }
  };
  calculateRenewalDate();
}, [errorMessage1]);

  // handle submit with subscription ended
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    const { password, email } = values;
    setLoading(true);

    try {
      const response = await axios.post(apiEndPoint.user, {
        username: email,
        password,
      });

      setLoading(false);

      if (response.data.status === "success") {
        dispatch(login(response.data.data));
        localStorage.setItem("authToken", response.data.token);
        toast.success("Login Successful");
     
        const user = response.data.data.name;
        //  console.log("userName:", user);
        setUserName(`${user}`)
 
        const remainingDays = response.data.remaining_days;
        //console.log("Remaining Days:", remainingDays);
        if (remainingDays <=20) {
          setErrorMessage1(`${remainingDays}`);
          handleShowExpiredSoonModal(); 
        }
        setTimeout(() => {
          history.push("/dashboard");
        }, 5000);
  
      } else if (response.data.status === "failed") {
    const errorMessage = response.data.errors[0]?.message;
    if (errorMessage === "Token has expired") {
      setErrorMessage(errorMessage);
      toggleModal(); 
    } else {
      // Handle other failure cases as needed
      setErrorMessage("Login failed");
      toggleModal(); 
    }
  }
} catch (error) {
  console.error(error);
  // Check specifically for the "Token has expired" message
  const errorMessage = error.response?.data?.errors[0]?.message;
  if (errorMessage === "Token has expired") {
    setErrorMessage(errorMessage);
    toggleModal(); 
  } else {
    // Handle other errors
    toast.error("An error occurred while processing your request.");
  }
  setLoading(false);
}
}

  return (
    <div className="Containerr">
      <Row className="justify-content-md-center">
        <Col md="auto">
          <div className="logo">
            <img src={logo} alt="" />{" "}
          </div>
        </Col>
      </Row>

      {/* Logo */}
      <Row className="row justify-content-center">
        <Col xs={6} md={7}>
          <div className="landing-video ">
            {/*Landing Video showing at right side of Login page*/}
            <ReactPlayer
              className="react-player_Home"
              playing="true"
              loop={true}
              muted={false}
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                    controlsList: "nodownload",
                  },
                },
              }}
              controls={true}
              volume={0.3}
              url={video}
              width="100%"
              height="56.2vh"
            />
          </div>
          <div className="footer-copyright">
            Copyright © {fullYear} Dambaruu All Rights Reserved.
          </div>
        </Col>
      <Col xs={6} md={5}>
          <div className="form-Container">
            <div className="form-message">
              <h1>Login to Dambaruu</h1>
            </div>
            {/*Used Fromik for login form */}
            <Formik
              initialValues={{
                password: "",
                email: "",
              }}
              validationSchema={contactSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, values, handleChange }) => (
                <Form className="contact-form" name="contact-form">
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={values.email || ""}
                    onChange={handleChange}
                  />
                  <br />
                  <span style={{ fontSize: "14px", color: "#B22237" }}>
                    {errors && errors.email}
                  </span>
                  <br></br>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={values.password || ""}
                    onChange={handleChange}
                  />
                  <br />
                  <span style={{ fontSize: "14px", color: "#B22237" }}>
                    {errors && errors.password}
                  </span>
                  <br></br>
                  <button
                    disabled={
                      values.email.length === 0 || values.password.length === 0
                    }
                    type="submit"
                    id="loginbutton"
                    className="PushableLoginBtn"
                    onClick={start}
                  >
                    <div className="fonts">
                      {loading ? "Please Wait..." : "Login"}
                    </div>
                  </button>
                </Form>
              )}
            </Formik>

            {/* Render modal based on showModal state */}
          <Modal className="token_expired_modal" backdrop="static"
            keyboard={false} show={showModal} onHide={toggleModal} centered>
           <Modal.Body >
             <img src={token_expired} alt="Example"/>
             <p><RxCrossCircled className="token_expired_close_icon" onClick={toggleModal} /></p>
           </Modal.Body>
          </Modal>

          {/* Token Expiring Soon modal */}
          <Modal className="expire_soon_modal" backdrop="static"
              keyboard={false} show={showExpiredSoonModal} onHide={handleCloseExpiredSoonModal} centered>
            <Modal.Body >
             <img src={expired_soon} alt="Example"/>
             <p className="days_left_num">{`${errorMessage1}`}</p>
             <p className="days_left_text">{`day's left`}</p>
             {renewalDate && (
             <p className="renewal_date">{`${renewalDate}`}</p>
             )}
             {userName && (
             <p className="user_name">{`Dear ${userName}`}</p>
             )}
             <p><RxCrossCircled className="expire_soon_close_icon" onClick={handleCloseExpiredSoonModal} /></p>
            </Modal.Body>
         </Modal>
        </div>
     </Col>
     </Row>
</div>
  );
};
export default Login;