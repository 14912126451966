import React from 'react'
import "./Tracingalphabet.css";
import ColorPicker from '../ColorPicker/ColorPicker';
import Tesseract from 'tesseract.js';
import Header from '../Header/Header';
import { useHistory } from "react-router";
import axios, { config } from "../../utils/api";
import { useState, useEffect,useRef } from "react";
import { apiEndPoint } from "../../utils/constants";
import { fabric } from 'fabric';
import sucessimg from '../../Asset/cards/numbergame/correct-awesome.png'
import sryimg from '../../Asset/cards/numbergame/You-lose.png'
import Modal from 'react-bootstrap/Modal';
import { GrFormNext,GrFormPrevious } from "react-icons/gr";

const Tracingalphabet = () => {
  const [thumbnailData, setThumbnailData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [extractedText, setExtractedText] = useState('');
  const [clearText, setClearText] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [imageData, setImageData] = useState([]);
  const [currentAlphabetIndex, setCurrentAlphabetIndex] = useState(0);
  const [currentThumbnailIndex, setCurrentThumbnailIndex] = useState(0);
  const [soundPlayed, setSoundPlayed] = useState(false);
  const [nextThumbnailName, setNextThumbnailName] = useState('');
  const [previousThumbnailName, setPreviousThumbnailName] = useState('');
  const soundPlayedRef = useRef(false);
  const history = useHistory();
  const audioRef = useRef(null);
 
  // modal part states
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);


  const queryParams = new URLSearchParams(window.location.search);
  const thumbnailsId = queryParams.get("thumbnails_id");
  const currentThumbnail = thumbnailData.findIndex((item) => item.id === thumbnailsId);

  useEffect(() => {
    if (currentThumbnail < thumbnailData.length - 1) {
      const nextThumbnail = thumbnailData[currentThumbnail + 1];
      setNextThumbnailName(nextThumbnail.thumbnails_name);
    }
    if (currentThumbnail > 0) {
      const previousThumbnail = thumbnailData[currentThumbnail- 1];
      setPreviousThumbnailName(previousThumbnail.thumbnails_name);
    }
  }, [currentThumbnail, thumbnailData]);

  //previousThumbnail  button logic 
  const handlePreviousThumbnail = () => {
    if (currentThumbnail> 0) {
      const previousThumbnail = thumbnailData[currentThumbnail - 1];
      setCurrentThumbnailIndex((prevIndex) => prevIndex - 1);
      handleClearCanvas();
      console.log("Previous Thumbnail Name:", previousThumbnail.thumbnails_name);
      const previousThumbnailId = previousThumbnail.id;
      fetchActivityGifData(previousThumbnailId);
      // Update the currentAlphabetIndex based on the selected thumbnail
      const correspondingAlphabetIndex = thumbnailData.findIndex(
        (item) => item.id === previousThumbnailId
      );
      setCurrentAlphabetIndex(correspondingAlphabetIndex);
      redirectTracingalphabetPage(previousThumbnailId);
      window.location.reload();
    }
  };
  
  const redirectTracingalphabetPage = (id) => {
    const queryParams = new URLSearchParams(window.location.search);
    const activityId = queryParams.get("activity_id");
    history.push(`/tracingalphabet?activity_id=${activityId}&thumbnails_id=${id}`);
  };
// tracingAlphabets API endpoint fetch
  const fetchActivityGifData = async (thumbnailsId) => {
    try {
      const response = await axios.get(`${apiEndPoint.tracingalphabet}?filters=${thumbnailsId}`, config());
      const activityGifData = response.data.data;
      // Use activityGifData as needed, for example, set it to a state variable or perform other actions
      console.log('Activity Gif Data:', activityGifData);
    } catch (error) {
      console.error('Error fetching activity gif data:', error);
    }
  };

  //next alphabet button(#sital) 
  const handleNextThumbnail = () => {
    if (currentThumbnailIndex < thumbnailData.length - 1) {
      const nextThumbnail = thumbnailData[currentThumbnail + 1];
      setCurrentThumbnailIndex((prevIndex) => prevIndex + 1);
      handleClearCanvas();
      setNextThumbnailName(nextThumbnail.thumbnails_name);
      const nextThumbnailId = nextThumbnail.id;
      fetchActivityGifData(nextThumbnailId);
      redirectTracingalphabetPage(nextThumbnailId)
      window.location.reload();
    }
  };
  // thumnail endpoints fetch for next and previous buton 
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const activityId = queryParams.get("activity_id");
    const filter = JSON.stringify({
      activity_id: activityId,
    });
    const fetchData = async () => {
      setLoading(true);
      await axios
      .get(`${apiEndPoint.thumbnails}?filters=${filter}`, config()).then((res) => {
        if (res.data && res.data.data) {
          setThumbnailData(res.data.data);
        }
        setLoading(false);
      });};
    fetchData();
  },[]);

  //congrats gif timeout function
  const closeModalsAfterDelay = (id) => {
    setTimeout(() => {
      setShow(false);
      history.push(`/thumbnails?activity_id=${id}`);
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  useEffect(() => {
    if (show) {
      const queryParams = new URLSearchParams(window.location.search);
      const activityId = queryParams.get("activity_id");
      closeModalsAfterDelay(activityId);
    }
    if (!soundPlayedRef.current) { // Only play the sound if it hasn't been played yet
      playSound();
      soundPlayedRef.current = true;
    }
  }, [show]);

  // sorry gif timeout function 
  const closeModalAfterDelay = () => {
    setTimeout(() => {
      setShows(false);
    }, 2000); // 2000 milliseconds = 2 seconds
  }
  useEffect(() => {
    if (shows) {
    closeModalAfterDelay();
    }
  }, [shows]);

  const canvasRef = useRef(null);
  // color picker part
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [pencilWidth, setPencilWidth] = useState(15);

  const handleClearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.getObjects().forEach((obj) => {
        canvas.remove(obj);
      });
      canvas.renderAll();
      setErrorMessage('');
      setConfirmMessage('');
      setClearText(true);
    }
  };

  // Function to handle successful completion of an alphabet tracing
  const handleClearSuccess = () => {
    // Clear the canvas and any necessary state variables
    handleClearCanvas();
    setClearText(true);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const thumbnailsId = queryParams.get("thumbnails_id");
    const filters = JSON.stringify({
      thumbnails_id: thumbnailsId,
    });
    const fetchTraceAlphabetData = async () => {
      setLoading(true);
      await axios
        .get(`${apiEndPoint.tracingalphabet}?filters=${filters}`, config())
        .then((res) => {
          setImageData(res.data.data);
          setLoading(false);
        });
    };
    fetchTraceAlphabetData();
  }, [setImageData]);

  // set color 
  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handlePencilWidthChange = (width) => {
    setPencilWidth(width);
  };

  // Initialize the canvas on component mount
  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current, {
      isDrawingMode: true
    });
    canvas.freeDrawingBrush.width = pencilWidth;
    canvas.freeDrawingBrush.color = selectedColor;
    // Store the canvas object in the ref
    canvasRef.current = canvas;
  }, []);

  // Update canvas brush color when selectedColor changes
  useEffect(() => {
   // console.log('TESSDATA_PREFIX:', process.env.TESSDATA_PREFIX);
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.freeDrawingBrush.width = pencilWidth;
      canvas.freeDrawingBrush.color = selectedColor;
      canvas.renderAll();
    }}, [selectedColor, pencilWidth]);

  const handleOCR = async () => {
   
    const canvas = canvasRef.current;
    if (canvas) {
      const imageDataURL = canvas.toDataURL();
    
   Tesseract.recognize(imageDataURL,"eng",
    { 
      
        logger: (info) => console.log('Tesseract.js Info:', info),
       
      workerPath: './tesseract/worker.min.js', // Adjust the path as necessary
      langPath:`/tessdata`, 
      
      corePath: '/Corepath/tesseract-core.wasm.js' ,// Adjust the path as necessary
    }

)
        .then(({ data: { text } }) => {
          console.log("OCR Result:", text);
          
          var str = text.trim();
          setExtractedText(text);
          console.log("Api Result:", imageData[currentAlphabetIndex].gif_name);
          if (str === imageData[currentAlphabetIndex].gif_name) {
            setShow(true);
            const audio = new Audio("/Congrats.wav");
            audioRef.current = audio;
            playSoundsucess();
            handleClearSuccess();
          } else {
            setShows(true);
            const audio = new Audio("/Sorry.wrong.wav");
            audioRef.current = audio;
            playSoundsucess();
            handleClearSuccess();
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage('Error occurred during recognition.');
        });
    }
  };
  
  useEffect(() => {
    if (extractedText && imageData[currentAlphabetIndex] && extractedText.trim() === imageData[currentAlphabetIndex].gif_name) {
      handleClearSuccess();
    }
  }, [extractedText, imageData, currentAlphabetIndex]);

// sucess sound play logic
const playSoundsucess = () => {
  if (audioRef.current) {
    audioRef.current.play();
  }
};

// Audio Logic for background
const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  useEffect(() => {
    if (imageData.length > 0 && !soundPlayed) { // Load the audio and play it when the component mounts
      const audio = new Audio(imageData[currentAlphabetIndex].gif_sound
      );
      audioRef.current = audio;
      playSound(); // Play sound on component mount
      setSoundPlayed(true);
    }}, [imageData, currentAlphabetIndex, soundPlayed]); // Add imageData, currentAlphabetIndex, and soundPlayed to the dependency array

  // Pause the sound when it ends
  useEffect(() => {
    const handleSoundEnded = () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleSoundEnded);
      return () => audioRef.current.removeEventListener('ended', handleSoundEnded);
    }
  }, []);

  useEffect(() => {
    const cleanup = () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
        soundPlayedRef.current = false;
      }
    };
    window.addEventListener('beforeunload', cleanup)
    return () => {
      cleanup();
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);
  
  return (
    <div className="StandardDashboard">
      <div className="container-trace-alphabet">
        <div className="right-section1" >
          <Header />
          <div className='canva'>
            <canvas
              style={{ marginLeft: '-5px', marginTop: '-10px' }}
              width={750}
              height={500}
              ref={canvasRef} />
           </div>
          <div className="trace-main-container">
            <h5>Trace Area</h5>
            <div className="trace-all">
              <div className='gif'>
                {imageData.length > 0 ? (
                  <img
                    className="trace-gif"
                    src={imageData[currentAlphabetIndex].gif_image_card}
                    alt="Image card"
                  />
                ) : (
                  <div></div>
                )}
              </div>
              <div className='gif-img'>
                {imageData.length > 0 ? (
                  <img
                    className="trace-img"
                    src={imageData[currentAlphabetIndex].gif_image}
                    alt="Image"
                  />
                ) : (
                  <div></div>
                )}
              </div>
              {/* modal part for congratulation */}
              <Modal
               show={show}
               onHide={handleClose}
               className={show ? 'slide-in-from-left' : ''}
              >
             <Modal.Body className='mod-body' style={{ backgroundColor: 'transparent !important' }}>
             <img src={sucessimg} className='cong-gif' alt="Success" />
             </Modal.Body>
             </Modal>
              {/* modal part for sorry */}
              <Modal show={shows}
                     onHide={handleCloses}
                     dialogClassName="slide-in-left-modal"
                     onEnter={() => document.body.classList.add('modal-open')}
                     onExit={() => document.body.classList.remove('modal-open')}
            >
              <Modal.Body className='mod-body' style={{ backgroundColor: 'transparent !important' }}>
                <img src={sryimg} className='sry-gif' alt="sry"/>
              </Modal.Body>
            </Modal>
            </div>
            <div className="footer-part">
              <ColorPicker onSelectColor={handleColorSelect} onPencilWidthChange={handlePencilWidthChange} />
              <div className='pencil-eraser' onClick={handleClearCanvas}></div>
              <div className="but-part">      
              <button className='rec-text' onClick={handleOCR}>Check</button>
              <button className='prev-letter-but' onClick={handlePreviousThumbnail}><GrFormPrevious size={30} style={{marginTop:-7}}/>{previousThumbnailName}</button>
              <button className='next-letter-but'  onClick={handleNextThumbnail}>{nextThumbnailName} <GrFormNext size={30} style={{marginTop:-7}} /></button>
            </div>
            </div>
            <div className='alert-msg'>
              {confirmMessage && <p>{confirmMessage}</p>}
              {errorMessage && <p>{errorMessage}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
export default Tracingalphabet;