import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router";
import Switch from "react-switch";
import correctimg from "../../Asset/images/correct_tick.png";
import wrongimg from "../../Asset/images/wrong_cross.png";
import congratulation from "../../Asset/cards/numbergame/correct-awesome.png"
import { IoSettingsOutline, IoVolumeHigh, IoHome,IoPlay  } from "react-icons/io5";
import { FaPause } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Header from '../Header/Header';
import "./MatchingLetters.css"

const MatchingLetters = ({}) => {
    const canvasRef = useRef(null);
    const dotData   = [
     [ { x: 70, y: 60, row: 'upper',value:"a" },   // Dot 1
      { x: 70, y: 250, row: 'lower',value:"c"},    // Dot 2
      { x: 320, y: 60, row: 'upper',value:"b"},   // Dot 3
      { x: 320, y: 250, row: 'lower',value:"d"},   // Dot 4
      { x: 570, y: 60, row: 'upper',value:"c"},   // Dot 5
      { x: 570, y: 250, row: 'lower',value:"b"},   // Dot 6
      { x: 820, y: 60, row: 'upper',value:"d"},   // Dot 7
      { x: 820, y: 250, row: 'lower',value:"a"},   // Dot 8
     ],
      [ { x: 70, y: 60, row: 'upper',value:"e" },   // Dot 1
      { x: 70, y: 250, row: 'lower',value:"g"},    // Dot 2
      { x: 320, y: 60, row: 'upper',value:"f"},   // Dot 3
      { x: 320, y: 250, row: 'lower',value:"h"},   // Dot 4
      { x: 570, y: 60, row: 'upper',value:"g"},   // Dot 5
      { x: 570, y: 250, row: 'lower',value:"e"},   // Dot 6
      { x: 820, y: 60, row: 'upper',value:"h"},   // Dot 7
      {  x: 820, y: 250, row: 'lower',value:"f"}, // Dot 8
      ],
      [ { x: 70, y: 60, row: 'upper',value:"i"},   // Dot 1
      { x: 70, y: 250, row: 'lower',value:"j"},    // Dot 2
      { x: 320, y: 60, row: 'upper',value:"j"},   // Dot 3
      { x: 320, y: 250, row: 'lower',value:"i"},   // Dot 4
      { x: 570, y: 60, row: 'upper',value:"k"},   // Dot 5
      { x: 570, y: 250, row: 'lower',value:"l"},   // Dot 6
      { x: 820, y: 60, row: 'upper',value:"l"},   // Dot 7
      { x: 820, y: 250, row: 'lower',value:"k"}, // Dot 8
      ],
      [ { x: 70, y: 60, row: 'upper',value:"m" },   // Dot 1
      { x: 70, y: 250, row: 'lower',value:"o"},    // Dot 2
      { x: 320, y: 60, row: 'upper',value:"n"},   // Dot 3
      { x: 320, y: 250, row: 'lower',value:"p"},   // Dot 4
      { x: 570, y: 60, row: 'upper',value:"o"},   // Dot 5
      { x: 570, y: 250, row: 'lower',value:"m"},   // Dot 6
      { x: 820, y: 60, row: 'upper',value:"p"},   // Dot 7
      { x: 820, y: 250, row: 'lower',value:"n"}, // Dot 8
      ],
      [ { x: 70, y: 60, row: 'upper',value:"q" },   // Dot 1
      { x: 70, y: 250, row: 'lower',value:"s"},    // Dot 2
      { x: 320, y: 60, row: 'upper',value:"r"},   // Dot 3
      { x: 320, y: 250, row: 'lower',value:"t"},   // Dot 4
      { x: 570, y: 60, row: 'upper',value:"s"},   // Dot 5
      { x: 570, y: 250, row: 'lower',value:"q"},   // Dot 6
      { x: 820, y: 60, row: 'upper',value:"t"},   // Dot 7
      { x: 820, y: 250, row: 'lower',value:"r"}, // Dot 8
      ],
      [ { x: 70, y: 60, row: 'upper',value:"u" },   // Dot 1
      { x: 70, y: 250, row: 'lower',value:"w"},    // Dot 2
      { x: 320, y: 60, row: 'upper',value:"v"},   // Dot 3
      { x: 320, y: 250, row: 'lower',value:"u"},   // Dot 4
      { x: 570, y: 60, row: 'upper',value:"w"},   // Dot 5
      { x: 570, y: 250, row: 'lower',value:"x"},   // Dot 6
      { x: 820, y: 60, row: 'upper',value:"x"},   // Dot 7
      { x: 820, y: 250, row: 'lower',value:"v"}, // Dot 8
      ],
      [ { x: 70, y: 60, row: 'upper',value:"y" },   // Dot 1
      { x: 70, y: 250, row: 'lower',value:"z"},    // Dot 2
      { x: 320, y: 60, row: 'upper',value:"z"},   // Dot 3
      { x: 320, y: 250, row: 'lower',value:"b"},   // Dot 4
      { x: 570, y: 60, row: 'upper',value:"a"},   // Dot 5
      { x: 570, y: 250, row: 'lower',value:"y"},   // Dot 6
      { x: 820, y: 60, row: 'upper',value:"b"},   // Dot 7
      { x: 820, y: 250, row: 'lower',value:"a"}, // Dot 8
      ],
    ];

    const capitalLetters = [
      ['let_1.png', 'let_2.png', 'let_3.png', 'let_4.png'],
      ['let_5.png', 'let_6.png', 'let_7.png', 'let_8.png'],
      ['let_9.png', 'let_10.png', 'let_11.png', 'let_12.png'],
      ['let_13.png', 'let_14.png', 'let_15.png', 'let_16.png'],
      ['let_17.png', 'let_18.png', 'let_19.png', 'let_20.png'],
      ['let_21.png', 'let_22.png', 'let_23.png', 'let_24.png'],
      ['let_25.png', 'let_26.png', 'let_1.png', 'let_2.png'],
    ];
    
    const smallLetters = [
      ['slet_3.png', 'slet_4.png', 'slet_2.png', 'slet_1.png'],
      ['slet_7.png', 'slet_8.png', 'slet_5.png', 'slet_6.png'],
      ['slet_10.png', 'slet_9.png', 'slet_12.png', 'slet_11.png'],
      ['slet_15.png', 'slet_16.png', 'slet_13.png', 'slet_14.png'],
      ['slet_19.png', 'slet_20.png', 'slet_17.png', 'slet_18.png'],
      ['slet_23.png', 'slet_21.png', 'slet_24.png', 'slet_22.png'],
      ['slet_26.png', 'slet_2.png', 'slet_25.png', 'slet_1.png'],
    ];

    const [currentLine, setCurrentLine] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [lines, setLines] = useState([]);
    const [message, setMessage] = useState('');
    const [selectedPair, setSelectedPair] = useState(null);
    const [correctMatches, setCorrectMatches] = useState(0); 
    const [correctMatchesInSet, setCorrectMatchesInSet] = useState(0);
    const [currentSet, setCurrentSet] = useState(0);
    //console.log(currentSet)
    const [currentDot,setCurrentDot] = useState(0);
    const [count, setCount] = useState(0); 
    const [tempLine, setTempLine] = useState(null);
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(0.1);
   // console.log(volume)
    const [isAudioLoaded, setIsAudioLoaded] = useState(true);
    //console.log(isAudioLoaded)
    const correctAudioRef = useRef(new Audio('/Correct Answer.wav'));
    const incorrectAudioRef = useRef(new Audio('/Sorry.Wrong.wav'));
    const congratsAudioRef = useRef(new Audio('/Congrats.wav'));

    const isCapitalA = (letter) => letter === 'A';
    const [drawnCorrectMatches, setDrawnCorrectMatches] = useState([]);
    const [incorrectLines, setIncorrectLines] = useState([]);
 
    const audioRef = useRef(null); 
    const history = useHistory();

    const [showPauseBox, setShowPauseBox] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [pausedSetIndex, setPausedSetIndex] = useState(null);
       // setting modal states
    const [showSettings, setShowSettings] = useState(false);
    const handleCloseSettings = () => setShowSettings(false);
    const handleShowSettings = () => setShowSettings(true);
      // CongratulationModal modal states
    const [showCongratulationModal, setShowCongratulationModal] = useState(false);
    const handleCloseCongratulationModal = () => setShowCongratulationModal(false);
    const handleShowCongratulationModal = () => setShowCongratulationModal(true);

  useEffect(() => {
    const audio = new Audio('/FastFeelBananaPeel-320bit.mp3');
    audio.loop = true;
    audio.volume = isMuted ? 0 : volume;
    audioRef.current = audio; // Set the audio element reference
    const handleCanPlayThrough = () => {
      setIsAudioLoaded(true);
      audio.play();
    };
    audio.addEventListener('canplaythrough', handleCanPlayThrough);
    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener('canplaythrough', handleCanPlayThrough);
    };
  }, [isMuted,volume]);


  useEffect(() => {
    return () => {
      correctAudioRef.current.pause();
      correctAudioRef.current.currentTime = 0;

      incorrectAudioRef.current.pause();
      incorrectAudioRef.current.currentTime = 0;
    };
  }, []);

    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');// Draw dots at the specified points
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#194792';
        dotData[currentSet].forEach((dot) => {
          ctx.beginPath();
          ctx.arc(dot.x, dot.y, 10, 0, 12 * Math.PI);
          ctx.fill();
        });
        if (isDrawing && tempLine) {
          ctx.beginPath();
          ctx.moveTo(tempLine.start.x, tempLine.start.y);
          ctx.lineTo(tempLine.end.x, tempLine.end.y);
          ctx.strokeStyle = '#CB5B9F';
          ctx.lineWidth = 5;
          ctx.stroke();
        }
      lines.forEach((line) => {
        ctx.beginPath();
        ctx.moveTo(line.start.x, line.start.y);
        ctx.lineTo(line.end.x, line.end.y);
        ctx.strokeStyle = '#CB5B9F';
        ctx.lineWidth = 5;
        ctx.globalCompositeOperation = line.zIndex > 0 ? 'source-over' : 'destination-over';
        ctx.stroke();
      });
      ctx.globalCompositeOperation = 'source-over';
    
    }, [lines,isDrawing,tempLine]);
  
    const handleMouseDown = (e) => {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const clickedDot = dotData[currentSet].find((dot) => {
        const distance = Math.sqrt((x - dot.x) ** 2 + (y - dot.y) ** 2);
        return distance <= 20; // Check if the click is within the dot's radius
      });

      if (clickedDot) {
        handleDotClick(clickedDot);// Handle dot click here
        
      if (!currentLine) {
          setCurrentLine({ start: clickedDot, end: { x, y } });
          setIsDrawing(true);
        }
      }
    };
    const handleDotClick = (clickedDot) => {
      setSelectedPair(clickedDot); // Set the entire clickedDot object
    };
    const handleDotDrop = (end, selectedDot) => {
      const droppedDotLower = dotData[currentSet].find((dot) => {
        const distance = Math.sqrt(
          (end.x - dot.x) ** 2 + (end.y - dot.y) ** 2
        );
        return distance <= 10; // Check if the drop is within the dot's radius
      });
    
      if (selectedDot && selectedDot.row === 'upper' && droppedDotLower &&
       droppedDotLower.row === 'lower' && selectedDot.value === droppedDotLower.value || 
       selectedDot && selectedDot.row === 'lower' && droppedDotLower &&
       droppedDotLower.row === 'upper' && selectedDot.value === droppedDotLower.value ) {
        setMessage('Correct match');
        correctAudioRef.current.play();
        setTimeout(() => {
          setMessage(''); // Remove success message after 1 seconds
        }, 1000);
      
        // Check if the match was drawn before
      if (!drawnCorrectMatches.some((match) =>
      (match[0] === selectedDot.value && match[1] === droppedDotLower.value) ||
      (match[0] === droppedDotLower.value && match[1] === selectedDot.value))) {
        setCount((prevCount) => prevCount + 100);
        setCorrectMatchesInSet((prevCount) => prevCount + 1); // Increment correctMatchesInSet
        setSelectedPair(null); // Reset the selected pair
        setDrawnCorrectMatches([...drawnCorrectMatches, [selectedDot.value, droppedDotLower.value]]);
        if (correctMatchesInSet === 3) {
          // All 4 pairs matched correctly in the current set
          setTimeout(() => {
            handleCompleteSet();
          }, 1000);
        }
      }
      } else {
        setMessage('Incorrect match');
        incorrectAudioRef.current.play();
        setTimeout(() => {
          setMessage(''); // Remove success message after 1 seconds
        }, 1000);
        setSelectedPair(null); // Reset the selected pair
        clearIncorrectLines();
      }
    };
    
    const clearIncorrectLines = () => {
      setLines(lines.filter((line) => !incorrectLines.includes(line)));
    };

    useEffect(() => {
      // Check if all sets are completed
      if (currentSet >= 7) {
        // Reset the game to the first set
        setCurrentSet(0);
        setLines([]);
        setCurrentDot(0);
        setCorrectMatches(0);
        setCorrectMatchesInSet(0);
        setMessage('');
        clearCanvas();
        setCount(0); // Reset the count
      }
    }, [currentSet]);
    
    const handleCompleteSet = () => {
      setMessage('Set complete!');
      setCorrectMatches((prevCorrectMatches) => prevCorrectMatches + correctMatchesInSet);
      setCorrectMatchesInSet(0);
      setDrawnCorrectMatches([]);
      setIncorrectLines([]);
      setShowCongratulationModal(true);
      congratsAudioRef.current.play();
      // After 1.5 second, remove the message and move to the next set
      setTimeout(() => {
        setShowCongratulationModal('');
        setLines([]);
        // Reset currentSet to 0 when it reaches 6, or move to the next set
        setCurrentSet((prevSet) => (prevSet + 1) % 7);
        if (currentSet === 6) {
          setCount(0);
        }
      }, 1500);
    };
    

const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

    const handleMouseMove = (e) => {
      if (isDrawing && currentLine) {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setCurrentLine({ ...currentLine, end: { x, y } });
        setTempLine({ start: selectedPair, end: { x, y } });
      }
    };
  
    const handleMouseUp = () => {
      if (isDrawing && currentLine && currentLine.end) {
        const zIndex = currentLine.start.position === 'front' ? 1 : -1;
        const updatedLine = {
          ...currentLine,
          zIndex,
        };
        setLines([...lines, updatedLine]);
        setCurrentLine(null);
        setIsDrawing(false);
        // Pass the selectedDot to handleDotDrop
        handleDotDrop(currentLine.end, selectedPair);
      }
    };
      // Toggle the mute state
  const handleToggleChange1 = () => {
    setIsMuted(!isMuted);
  };
    const home1 = () => {
      history.push("/dashboard");
    };

    const handlePauseClick = () => {
      // Pause the game and remember the current set index
      setIsPaused(true);
      setPausedSetIndex(currentSet);
      setShowPauseBox(true);
      audioRef.current.pause(); // Pause the background music
  };
  
   const handlePauseModalClose = () => {
    //close the modal and restart the game 
      setShowPauseBox(false);
      setIsPaused(false);
      setCurrentSet(pausedSetIndex);
      audioRef.current.play(); // Play the background music
    };

  return (
   <div className="App">
   <Header />
   <div className='matching-game'>
     {isAudioLoaded && (
    <>
    <Button className="pause-btn1" onClick={handlePauseClick}><FaPause  size="1.6rem" /></Button>
                {/* modal for pause button  */}
            <Modal show={showPauseBox} className="matching-pause-modal1" centered>
              <Modal.Header >
                <Modal.Title className="pause-title1">Game Paused</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="pause-para1">This game is paused now.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button className="play-btn1" variant="primary" onClick={handlePauseModalClose}>
                <IoPlay  size="2.5rem" />
                </Button>
              </Modal.Footer>
            </Modal>
     <div className='match-para'>
     <h1 className='match-para1'>Capital & SmallLetters Matching </h1>
     </div>
     <div className="corr-ans-count"> 
     <p className='count'>{count}
     <img src="./images/coin.png" alt="Coin" className="coin-image1" /></p>
     <Button className="set-btn1" variant="primary" onClick={handleShowSettings}><IoSettingsOutline size="1.6rem" />
     </Button>
      <Button className="home-btn1" variant="primary" onClick={home1}><IoHome size="1.5rem" /></Button>
      <div className='msg'> {message === 'Correct match' && <img src={correctimg} alt="Correct" />}
        {message === 'Incorrect match' && <img src={wrongimg} alt="Wrong" />}
        </div>
    </div>
        {currentSet < 13 ? (
          <div className="set">
          <div className="row11">
              {capitalLetters[currentSet].map((letter, index) => (
                <div
                  key={index}
                  className="letter"
                >
              <img
               src={process.env.PUBLIC_URL + '/images/' + letter}
                alt={`Letter ${String.fromCharCode(index + 65)}`}
              />
                </div>
                ))}
            </div>
            <canvas
            className='canvas_dot'
             ref={canvasRef}
             width={900}
             height={310}
             onMouseDown={handleMouseDown}
             onMouseMove={handleMouseMove}
             onMouseUp={handleMouseUp}
            >
            </canvas>
            <div className="row1">
              {smallLetters[currentSet].map((letter, index) => (
                <div
                  key={index}
                  className={`letter1 ${isCapitalA(letter) ? 'capital' : ''}`}
                >
                <img
                  src={process.env.PUBLIC_URL + '/images/' + letter}
                  alt={`Letter1 ${String.fromCharCode(index + 65)}`}
                />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="messages">All sets completed!</div>
        )}
        </>
     )}
     </div>
          {/* modal for setting button */}
          <Modal show={showSettings} className="matching-setting-modal1">
              <Modal.Header>
                <Modal.Title className="match-set-title">SETTING</Modal.Title>
              </Modal.Header>
            <Modal.Body>
              <div className="match-volume-icon"><IoVolumeHigh size="2rem" />
                <Switch className="match-switch"
                  onChange={handleToggleChange1}
                  checked={!isMuted}
                  onColor="#86d3ff"
                  offColor="#aaa"
                  checkedIcon={<div style={{ display: "flex", justifyContent: "center", 
                   alignItems: "center", height: "100%" }}>ON</div>}
                  uncheckedIcon={<div style={{ display: "flex", justifyContent: "center",
                   alignItems: "center", height: "100%" }}>OFF</div>}
                 />
              </div>
            </Modal.Body>
              <Modal.Footer>
                <Button className="match-back-btn" onClick={handleCloseSettings}>
                  BACK
                </Button>
              </Modal.Footer>
          </Modal>
           {/* modal for congratulation  */}
          <Modal show={showCongratulationModal} className="congrats-modal1" onHide={handleCloseCongratulationModal} centered>
            <Modal.Body>
              <img src={congratulation} className="complete-set1"/>
            </Modal.Body>
          </Modal>
    </div>
    )
  };
  export default MatchingLetters;