import React, { useState, useEffect } from 'react';
import './Number.css';
import Header from '../Header/Header';
import Loader from '../Loader';
import axios from '../../utils/api';
import { apiEndPoint } from '../../utils/constants';

const Number = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flippedCards, setFlippedCards] = useState({});
  const [currentSound, setCurrentSound] = useState(null);

  const handleClick = async (id, soundUrl) => {
    // Reset the flipped state of all cards except the clicked one
    const newFlippedCards = {};
    newFlippedCards[id] = !flippedCards[id];
    setFlippedCards(newFlippedCards);

    if (!flippedCards[id]) {
      // If the card is flipped, play the sound
      if (currentSound) {
        currentSound.pause();
        setCurrentSound(null);
      }
      const audio = new Audio(soundUrl);
      await audio.play();
      setCurrentSound(audio);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(apiEndPoint.number);
        if (res.data && res.data.data) {
          setData(res.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      if (currentSound) {
        currentSound.pause();
        setCurrentSound(null);
      }
    };
  }, [currentSound]);

  return (
    <div className="tracing-container">
      <Header />
      <div>
        <h1>Let's Play With Numbers</h1>
      </div>
      <div className="tracing-block">
        {loading ? (
          <div className="loading">
            <Loader />
          </div>
        ) : (
          <div className="All-tracing-block-card">
            <div className="tracing-block-card">
              {data.map((number) => (
                <div className="tracing-card-front" key={number.id}>
                  <img
                    src={flippedCards[number.id] ? number.image_back : number.image_front}
                    alt=""
                    onClick={() => handleClick(number.id, number.sound)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Number;