import React, { useState, useEffect, useRef } from "react";
import Header from '../Header/Header';
import axios, { config } from "../../utils/api";
import { useHistory } from "react-router";
import Switch from "react-switch";
import { IoSettingsOutline, IoVolumeHigh, IoHome,IoPlay  } from "react-icons/io5";
import { FaPause } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import congratulation from "../../Asset/cards/numbergame/correct-awesome.png"
import "./Subtraction.css";
import { apiEndPoint } from "../../utils/constants";
import Loader from "../Loader";

function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

function Subtraction() {
  const [sets, setSets] = useState([]);
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");
  const [loading, setLoading] = useState(false);
  const congratsAudio = new Audio("/Congrats.wav");
  const [isMuted, setIsMuted] = useState(false);
  const [count, setCount] = useState(0); 
  const [volume, setVolume] = useState(0.1);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showPauseBox, setShowPauseBox] = useState(false);
  const audioRef = useRef(null); 
  const history = useHistory();
    // setting modal states
  const [showSettings, setShowSettings] = useState(false);
  const handleCloseSettings = () => setShowSettings(false);
  const handleShowSettings = () => setShowSettings(true);
    // CongratulationModal modal states
  const [showCongratulationModal, setShowCongratulationModal] = useState(false);
  const handleCloseCongratulationModal = () => setShowCongratulationModal(false);
  const handleShowCongratulationModal = () => setShowCongratulationModal(true);

  const [allImages, setAllImages] = useState([]);

 const fetchImages = async () => {
    try {
      const response = await axios.get(apiEndPoint.subtraction, config());
      const images = response.data.data.map(item => item.image);
      setAllImages(images); // Save all images in state
      return images;
    } catch (error) {
      console.error("Error fetching images:", error.response || error);
      throw new Error("Failed to fetch images");
    }
  };

  useEffect(() => {
    const audio = new Audio('/FastFeelBananaPeel-320bit.mp3');
    audio.loop = true;
    audio.volume = isMuted ? 0 : volume;
    audioRef.current = audio; // Set the audio element reference
    const handleCanPlayThrough = () => {
      setIsAudioLoaded(true);
      audio.play();
    };
    audio.addEventListener('canplaythrough', handleCanPlayThrough);
    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener('canplaythrough', handleCanPlayThrough);
    };
  }, [isMuted,volume]);

  const generateSubtractionSets = async () => {
    setLoading(true);
    const newSets = [];
  
    // Shuffle the order of images once and use the shuffled array sequentially
    const shuffledImages = shuffleArray(allImages);
  
    for (let i = 0; i < 3; i++) {
      let num1, num2;
      let correctAnswer;
  
      // Ensure unique correct answers for each set
      do {
        num1 = Math.floor(Math.random() * 8) + 2;
        num2 = Math.floor(Math.random() * (num1 - 1)) + 1;
        correctAnswer = num1 - num2;
      } while (newSets.some(set => set.correctAnswer === correctAnswer));
      try {
        const selectedImage = shuffledImages[i % shuffledImages.length];
  
        const imageSetNum1 = Array.from({ length: num1 }, () => selectedImage);
        const imageSetNum2 = Array.from({ length: num2 }, () => selectedImage);
  
        const correctOptionImages = imageSetNum1.concat(imageSetNum2);
        const correctOption = {
          value: correctAnswer,
          images: correctOptionImages,
        };
  
        const allOptions = shuffleArray([correctOption]);
  
        newSets.push({
          num1,
          num2,
          correctAnswer,
          options: allOptions,
          userAnswer: null,
        });
      } catch (error) {
        console.error("Error generating addition set:", error);
      }
    }
  
    setSets(newSets);
    setLoading(false);
  };

  useEffect(() => {
    fetchImages(); // Fetch images only once when the component mounts
  }, []);

  useEffect(() => {
    generateSubtractionSets();
  }, [currentSetIndex, allImages]);

  const handleSetCompletion = () => {
    setTimeout(() => {
      setMessage("");
      const allCompleted = sets.every(set => set.userAnswer !== null);
      if (allCompleted) {
        if (currentSetIndex < 9) {
          // Show correct modal here
          setShowCongratulationModal(true);
          congratsAudio.play();
          // After a delay, move to the next set
          setTimeout(() => {
            setShowCongratulationModal(false);
            setCurrentSetIndex(currentSetIndex + 3); // Move to the next set of 3
            generateSubtractionSets(); // Regenerate sets after completing a set
          }, 2000); // Adjust the delay as needed
        } else {
          setCurrentSetIndex(0);
          setCount(0);
          setSets(sets.map(set => ({ ...set, userAnswer: null })));
          generateSubtractionSets(); // Regenerate sets after completing all sets
        }
      }
    }, 1500);
  };

  const handleDrop = (event, setIndex, optionIndex) => {
    event.preventDefault();
    const droppedAnswer = event.dataTransfer.getData("text/plain");
    const updatedSets = [...sets];
    const currentSet = updatedSets[setIndex];

    if (parseInt(droppedAnswer) === currentSet.correctAnswer) {
      currentSet.userAnswer = parseInt(droppedAnswer);
      setMessage("Correct!");
      setMessageClass("correct-message1");
      setCount((prevCount) => prevCount + 10);
      setTimeout(() => {
        setMessage("");
        const allCompleted = updatedSets.every(set => set.userAnswer !== null);
        if (allCompleted) {
          handleSetCompletion();
        }
      }, 1000);
    } else {
      // Display the incorrect answer temporarily
      currentSet.userAnswer = parseInt(droppedAnswer);
      setMessageClass("wrong-message1");
  
      setTimeout(() => {
        currentSet.userAnswer = null;
        setMessageClass("");
      }, 1000);
    }
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    generateSubtractionSets();
  }, [currentSetIndex]);

   // Toggle the mute state
   const handleToggleChange3 = () => {
    setIsMuted(!isMuted);
  };

  const Home = () => {
    history.push( "/dashboard" );
  };

  const handlePauseClick = () => {
    // Pause the game and remember the current set index
    setIsPaused(true);
    setShowPauseBox(true);
    audioRef.current.pause(); // Pause the background music
};

 const handlePauseModalClose = () => {
  //close the modal and restart the game 
    setShowPauseBox(false);
    setIsPaused(false);
    audioRef.current.play(); // Play the background music
  };

  return (
    <div className="subtraction-main-container">
      <Header />
      <p className="sub-para">ONE DIGIT SUBTRACTION</p>
      <div className="sub-ans-count">
              <p className="sub-count-value">
              {count}
              <img src="./images/coin.png" alt="Coin" className="sub-coin-image" />
              </p>
              <Button className="sub-set-btn" variant="primary" onClick={handleShowSettings}><IoSettingsOutline size="1.6rem" /></Button>
                    {/* modal for setting button */}
          <Modal show={showSettings} className="sub-setting-modal1">
              <Modal.Header>
                <Modal.Title className="sub-set-title">SETTING</Modal.Title>
              </Modal.Header>
            <Modal.Body>
              <div className="sub-volume-icon"><IoVolumeHigh size="2rem" />
                <Switch className="sub-switch"
                  onChange={handleToggleChange3}
                  checked={!isMuted}
                  onColor="#86d3ff"
                  offColor="#aaa"
                  checkedIcon={<div style={{ display: "flex", justifyContent: "center", 
                   alignItems: "center", height: "100%" }}>ON</div>}
                  uncheckedIcon={<div style={{ display: "flex", justifyContent: "center",
                   alignItems: "center", height: "100%" }}>OFF</div>}
                 />
              </div>
            </Modal.Body>
              <Modal.Footer>
                <Button className="sub-back-btn" onClick={handleCloseSettings}>
                  BACK
                </Button>
              </Modal.Footer>
          </Modal>
              <Button className="sub-home-btn" variant="primary" onClick={Home}><IoHome size="1.5rem" /></Button>
          </div>
          <Button className="sub-pause-btn" onClick={handlePauseClick}><FaPause  size="1.6rem" /></Button>
                {/* modal for pause button  */}
            <Modal show={showPauseBox} className="sub-pause-modal1" centered>
              <Modal.Header >
                <Modal.Title className="sub-pause-title">Game Paused</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="sub-pause-para">This game is paused now.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button className="sub-play-btn" variant="primary" onClick={handlePauseModalClose}>
                <IoPlay  size="2.5rem" />
                </Button>
              </Modal.Footer>
            </Modal>
       {loading ? ( // Render loader when loading is true
        <Loader />
      ) : (
      <div className="sub-set">
        {sets.map((currentSet, setIndex) => (
          <React.Fragment key={setIndex}>
            <div
              className="sub-equation"
              onDrop={(e) => handleDrop(e, setIndex, currentSetIndex)}
              onDragOver={(e) => allowDrop(e)}
            >
              <div className="sub-equation-box">
                <div className="sub-equation-num1">
                  {currentSet.num1 > 0 &&
                    currentSet.options[0].images.slice(0, currentSet.num1).map((image, imageIndex) => (
                      <img className="sub-equation-image" key={imageIndex} src={image} alt={`Image ${imageIndex}`} />
                    ))}
                </div>
                <span style={{color:'white'}}>-</span>  
                <div className="sub-equation-num2">
                  {currentSet.num2 > 0 &&
                    currentSet.options[0].images.slice(currentSet.num1, currentSet.num1 + currentSet.num2).map((image, imageIndex) => (
                      <img className="sub-equation-image" key={imageIndex} src={image} alt={`Image ${imageIndex}`} />
                    ))}
                </div>
                <span style={{color:'white'}}>=</span> 
                {currentSet.userAnswer !== null ? (
                  <div className="sub-answer">
                <span className={`add-answer-number ${currentSet.userAnswer === currentSet.correctAnswer ? 'correct-message' : 'wrong-message'}`}>
                  {currentSet.userAnswer}
               </span>
                  </div>
                ) : (
                  <span className="sub-equation-placeholder"> </span>
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
        <div className="sub-options-row">
          {sets.map((currentSet, setIndex) => (
            <React.Fragment key={setIndex}>
              {currentSet.options.map((option, optionIndex) => (
                <div
                  key={optionIndex}
                  draggable="true"
                  onDragStart={(e) => e.dataTransfer.setData("text/plain", option.value)}
                >
                  <div className="sub-option-number">
                    {option.value}
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
            {/* modal for congratulation  */}
  <Modal show={showCongratulationModal} className="sub-congrats-modal1" onHide={handleCloseCongratulationModal} centered>
   <Modal.Body>
    <img src={congratulation} className="sub-complete-set1"/>
    </Modal.Body>
  </Modal>
      </div>
      )}
    </div>
  );
}
export default Subtraction;